<template>
    <div class="home">
      <Header/>
      <div class="content"> 
        <div class="title">{{$t('m.sqjd')}}</div>    
         <van-tabs v-model="current" swipeable class="tab" background="transparent" color="#35dcf7" 
        line-height="2px"
        line-width="20px"
        @change="changer"
        title-active-color="#ffffff">
    <van-tab :title="$t('m.gsjd')" class="item">
    </van-tab>
    <van-tab :title="$t('m.dqjx')" class="item">
    </van-tab>
    </van-tabs>
     <div class="card" v-if="current==0">
      <div class="titles">{{$t('m.gsjd')}}</div>
      <div class="box">
        <div class="cont">
         <div class="ibox">
          <div class="txts">{{$t('m.gsjdzsl')}}</div>
          <div class="rbox">
            <div class="num">51</div>
          </div>
         </div>
        </div>
        <div class="cont">
         <div class="ibox">
          <div class="txts">{{$t('m.gsjdydcsl')}}</div>
          <div class="rbox">
            <div class="num">{{countNum||0}}</div>
          </div>
         </div>
        </div>
        <div class="cont">
         <div class="ibox">
          <div class="txts">{{$t('m.dqgsjdjc')}}</div>
          <div class="rbox">
            <div class="num">{{nodePool||0}}</div>
            <div class="txt">ARCT</div>
            <img src="../assets/img/slogo.png" alt="" srcset="" class="ico">
          </div>
         </div>
        </div>
        <div class="cont">
         <div class="ibox">
          <div class="txts">{{$t('m.wdjdjc')}}</div>
          <div class="rbox">
            <div class="num">{{userInfo.nodeReward}}</div>
            <div class="txt">ARCT</div>
            <img src="../assets/img/slogo.png" alt="" srcset="" class="ico">
          </div>
          <div class="btngroup">
            <div class="btns" @click="toLqShow(userInfo.nodeReward,1)">{{$t('m.lq')}}</div>
            <div class="btn"  @click="toPage('/detail?type=2')">{{$t('m.mx')}}</div>
          </div> 
         </div>
        </div> 

      </div>   
      <div class="buttom"> <van-button
          :loading="loading"
          type="primary"
          :class="userInfo.isNode?'btn act':'btn'"
        >
         {{ userInfo.isNode?$t('m.ydcjd'):$t('m.wdcjd')}}</van-button
        >
       
        
        <div class="tip">
            {{$t('m.djzlmm')}}  
        </div>
      </div>  
     </div>
     <!-- <div class="card" v-if="current==0" @@click="toGetList">
          <div class="titles">{{$t('m.jdmx')}}</div>
          <div class="box">
            <div class="cont" >
              <div class="xh">{{$t('m.xh')}}</div>
              <div class="xh">{{$t('m.xqyj')}}</div>
              <div class="address">{{$t('m.dz')}}</div>
            </div>
            <div class="cont" v-for="(item,i) in list" :key="i">
              <div class="xh">{{i+1}}</div>
              <div class="address">0</div>
              <div class="address">0x00000000000000</div>
            </div>            
          </div>
     </div> -->
   <div class="card" v-if="current==1">
    <div class="titles">{{$t('m.dqjx')}}</div>
    <div class="box">
        <div class="cont">
         <div class="ibox hbg">
          <div class="txts">{{$t('m.sqjxjc')}}</div>
          <div class="rbox">
            <div class="num">{{rankPool||0}}</div>
            <div class="txt">ARCT</div>
            <img src="../assets/img/slogo.png" alt="" srcset="" class="ico">
          </div>
         </div>
        </div>
        <div class="line"></div>
        <div class="cont">
         <div class="ibox">
          <div class="txts">{{$t('m.wdsqjxjc')}}</div>
          <div class="rbox">
            <div class="num">{{userInfo.rankReward||0}}</div>
            <div class="txt">ARCT</div>
            <img src="../assets/img/slogo.png" alt="" srcset="" class="ico">
          </div>
          <div class="btngroup">
            <div class="btns" @click="toLqShow(userInfo.rankReward,2)">{{$t('m.lq')}}</div>
            <div class="btn" @click="toPage('/detail?type=3')">{{$t('m.mx')}}</div>
          </div> 
         </div>
        </div>
    </div>
    <div class="buttom">
        <van-button
          :loading="loading"
          type="primary"
          :class="isNode?'btn act':'btn'"
        >
         {{ isNode?$t('m.ydcjd'):$t('m.wdcjd')}}</van-button
        >
        <div class="tip">
            {{$t('m.xqyjmm')}}
        </div>
      </div> 
   </div>
      <div class="card" v-if="current==1" @click="toGetLists">
          <div class="titles">{{$t('m.sqpm')}}</div>
          <div class="box">
            <div class="cont">
              <div class="xh">{{$t('m.pm')}}</div>
               <div class="xh">{{$t('m.xqyj')}}</div>
               <div class="xh">{{$t('m.zyj')}}</div>
              <div class="address">{{$t('m.dz')}}</div>
            </div>
            <div class="cont" v-for="(item,i) in lists" :key="i">
              <div class="xh">{{i+1}}</div>
                <div class="address">{{(item.subCommunityPerformance/10**18)||0}}</div>
               <div class="address">{{(item.communityPerformance/10**18)||0}}</div>
              <div class="address">{{item.rankAddr|yingcang}}</div>
            </div>            
          </div>
     </div>
    </div>
    <Bottom/>

     <!-- 领取余额 -->
     <van-popup v-model="lqshow" round :close-on-click-overlay="false">
      <div class="popup">
           <div class="top"> <div class="title">{{$t('m.qrlq')}}</div>
        <img src="../assets/img/gbs.png" alt="" class="close" v-if="!lqloading" @click="lqshow=false"/>
    </div>
        <div class="sqtxt">{{$t('m.ny')}}</div>
          <div class="rbox">
            <div class="num">{{lqNum||0}}</div>
            <div class="txt">ARCT</div>
            <img src="../assets/img/slogo.png" alt="" srcset="" class="ico">
          </div> 
        <div class="flsz"> 
         <van-button
          :loading="lqloading"
          type="primary"
          @click="scSelect"
          class="btn"
        >
         {{$t('m.lqlj')}}</van-button
        >
        </div>
      </div>
    </van-popup>
</div>
</template>
<script>
import Header from '../components/header.vue';
import Bottom from '../components/bottom.vue';
import {getMyHistoryStatic,getMyRankUsers,toWithdrawalNode,toWithdrawalRank,getMyNodeCount,getMyNodePool,getMyRankPool,getUser} from  "@/api/Public";
export default {
  name: "myContracts",
  components: {Header,Bottom},
  data() {
    return {
        current:0,
        loading:false,
        dcshow:true,
        list:[],
        lists:[],
        lqNum:0,
        lqType:0,
        lqshow:false,
        lqloading:false,
        countNum:0,
        nodePool:0,
        rankPool:0,
        isNode:false,
        userWallet:"",
        userInfo:""
    }
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{8})\w+(\w{8})/, "$1...$2");
        return a;
      } else {
        return "";
      }
    },
  },
  created(){
    // this.toGetList()
    this.userWallet=this.$store.getters.userWallet
    this.getInfo()
    this.getUsers()
  },
  methods:{
    toLqShow(num,t){
      this.lqNum=num
      this.lqType=t
      this.lqshow=true
    },
    async scSelect(){
    if(this.lqNum<=0){return}
    this.lqloading=true
    if(this.lqType==1){
        toWithdrawalNode(this.userWallet,this.lqNum).then(res=>{
        this.lqloading=false
        if(res){
            this.lqshow=false
            this.getUsers()
           this.$notify({
                message: this.$i18n.t("m.lqsg") ,
                type: "success",
              });
        }
      })
    }
    if(this.lqType==2){
        toWithdrawalRank(this.userWallet,this.lqNum).then(res=>{
        this.lqloading=false
        if(res){
          this.lqshow=false
          this.getUsers()
          this.$notify({
                message: this.$i18n.t("m.lqsg") ,
                type: "success",
              });
        }
      })
    }
    },
   async getInfo(){
    // this.info1= await 
    getMyNodeCount().then(res=>{this.countNum=res})
    getMyNodePool().then(res=>{this.nodePool=res})
    getMyRankPool().then(res=>{this.rankPool=res})
 
    },
    getUsers(){
      if(this.$store.getters.userWallet){
        getUser(this.$store.getters.userWallet).then(res=>{
        this.userInfo=Object.assign({},res)               
        this.userInfo.nodeReward=(res.nodeReward/10**18).toFixed(4)
        this.userInfo.rankReward=(res.rankReward/10**18).toFixed(4)         
      })
      }
    },
    toPage(val) {    
       this.$router.push({ path: val });
    },
    changer(e){
        this.current=e
        this.getUsers()
        if(e==1){
        this.toGetLists()
        }
    },
    toGetList(){
      if(this.$store.getters.userWallet){
        getMyHistoryStatic(this.$store.getters.userWallet,50).then(res=>{
            this.list=res
            console.log(res);
        })
      }
    },
    toGetLists(){       
        getMyRankUsers().then(res=>{
            this.lists=res
            if(res){
              res.map(el=>{
              let isNode=this.matchStringIgnoreCase(el.rankAddr,this.userWallet)
              if(isNode){this.isNode=true}
                console.log(this.isNode,"isnode");     
              })
            }
        })
    },
    
  matchStringIgnoreCase(text, pattern) {
    const regex = new RegExp(pattern, 'i');
    return regex.test(text);
  }
  }
}

</script>
<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";

$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";

.home {
  background:  #000000 url("../assets/img/pbg.png")  no-repeat;
  // background: #161628;
  background-size:contain;
  background-position: center bottom;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
    .content{
        margin-top: 188px;
        .title{
      font-size: 38px;
      font-weight: bold;
      color: #ffffff;
      margin: 72px auto;
      border-radius: 36px 0 36px 0;
      width: 360px;
      padding: 22px;
      position: relative;
    }
    .title::after{
      content: "";
      position: absolute;
      height: 4px;
      width: 100px;
      border-radius: 16px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(200deg, #12c2fc 0%, #3ae2f8 31%, #14a5d0 49%, #441dda 79%, #0b1042 100%);
    }
     .tab{
            width: 100%;
            border-bottom:1px solid #2A2A2A ;
            margin-top: 40px;
                padding-bottom: 20px;
          
            .item{
                flex: 1;
                color: #ffffff;
                padding-bottom: 20px;
                font-size: 28px !important;
            }
            .act{
                border-bottom: 1px solid #fc00ff;
            }
        }
        .card {
      // width: 694px;
      // background:#ffffff url("../assets/img/bgs.png") no-repeat ;
      background: #00000070;
         background-size: cover;
    background-position: left top;
          align-items: center;
          border: 1px solid rgb(53, 53, 53);
      border-radius: 60px;
      padding: 30px 40px;
      margin: 20px 30px 20px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .titles{
        font-size: 28px;
          font-weight: 600;
          color: #ffffff;
          padding-bottom: 30px;
          width: 100%;
          border-bottom: 1px solid rgb(53, 53, 53);
      }
      
      .box{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .tips{
           font-size: 24px;
           color: #ABABAB;
           margin-bottom: 40px;
          }
          .line{
            width: 100%;
            height: 1px ;
            background: #353535;
            margin-top: 40px;
          }
        
        .cont{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          .left{
            font-size: 24px;
            color: #FFFFFF;
            opacity: 0.6;
          }
          .xh{
            font-size: 24px;
            color: #FFFFFF;
            min-width: 70px;
            margin: 16px 0;
          }
          .address{
            font-size: 24px;
            color: #FFFFFF;
            min-width: 140px;
            max-width: 25%;
            margin: 16px 0;
            overflow: auto;
            white-space: nowrap;
          }
          .address::-webkit-scrollbar {
          display: none;
          }
          .right{
            .sinput{
              width:220px;
              border-radius: 100px;
              height: 64px;
              background: #2A2A2A;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .txt{
                color: #717171;
                margin-right: 16px;
              }
            }
          }
          .input{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            background: #2A2A2A;
            border-radius: 100px;
            height: 64px;
            padding: 8px 20px 8px 30px;
            box-sizing: border-box;     
            margin: 32px 0;       
            .yinput{
              border: none;
              background: transparent;
              color: #ffffff;
              font-size: 28px;
              font-weight: bold;
            }
            .dw{
              display: flex;
              align-items: center;
              .tt{
                color: #ffffff;
                font-size: 28px;
                font-weight: bold;
                margin:  0 10px;                
                white-space: nowrap;
              }
              .ico{
                width: 40px;
                height: 40px;
              }
            }
          }
          .icos{
            font-size: 36px;
            color: #ffffff;
          }
          .rbox{
            display: flex;
            align-items: center;
            justify-content: center;
            .num{
          //  margin: 22px auto;
        font-size: 38px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(180deg, #00d5ff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #ffffff;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
          }

         
          .ibox{
            border: 1px solid #fc00ff ;
            background: linear-gradient(135.46deg, rgba(6, 6, 6, 0.5) 24.8%, rgba(32, 32, 32, 0.5) 57.07%);
            border-radius: 40px;
            width: 100%;
            margin-top: 40px;
            padding: 30px 40px;
            box-sizing: border-box;
            .txts{
              color: #ffffff;
              font-size: 24px;
              margin-bottom: 20px;
            }
          }
          .hbg{
            background: #0e0e0e;
            border: none;
          }
          .btnr{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .btn{
          background: #2A2A2A;
            color: #ABABAB;
            border: none;
            border-radius: 100px;
            height: 68px;
            width: 250px;
            font-size: 24px;
          }
        .act{
          color: #ffffff;
          background: linear-gradient(188.1deg, #178caa 18.52%, #28c0de 71.67%);
          // background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
        }
          }
          .btngroup{
            display: flex;
            align-items: center;
            margin-top: 20px;
            .btns{
            background: linear-gradient(188.1deg, #178caa 18.52%, #28c0de 71.67%);
              color: #ffffff;
              border-radius: 56px;
              font-size: 28px;
              height: 52px;
              line-height: 52px;
              flex:1;
              border: 1px solid #2A2A2A;
            }
            .btn{
              background: transparent;
              color: #ffffff;
              border-radius: 56px;
              font-size: 28px;
              height: 52px;
              line-height: 52px;
              flex:1;
              border: 1px solid #fc00ff;
              margin-left: 20px;
            }
          }

        }
      }
      .buttom{
        display: flex;
        flex-direction: column;
        width: 100%;    
        margin: 40px auto 30px;  
        .ubtom{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          .btn{
            width: 45%;
          }
        }
        .btn{
         background: #2A2A2A;
          color: #ABABAB;
          border: none;
          border-radius: 100px;
          height: 68px;
          width: 100%;
          font-size: 24px;
        }
        .act{
          color: #ffffff;
          background: linear-gradient(188.1deg, #178caa 18.52%, #28c0de 71.67%);
          // background: linear-gradient(188.1deg, rgb(175, 57, 239) 18.52%, rgb(139, 61, 206) 71.67%);
        }
        .tip{
            color: #ABABAB;
            font-size: 20px;
            margin-top: 40px;
            // white-space: nowrap;
        }
      }
        
       

       .imggrop{
          display: flex;
          align-items: center;
          justify-content: center;
          .im1{
            width: 144px;
            height: 144px;
            z-index: 2;
            
          }.im2{
             width: 144px;
            height: 144px;
            margin-left: -60px;
            z-index: 1;
          }
        }
        .ttx{
          font-size: 36px;
          font-family: $t-f;
          font-weight: 600;
          color: #ffffff;
          line-height: 50px;
          text-align: center;
        }
      
        
      .sbot {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex: 1;
        width: 100%;
        text-align: center;
        margin: 24px 0;
          .txt {
            height: 56px;
            font-size: 46px;
            font-family: $t-mf;
            font-weight: 600;
            line-height: 54px;
            text-align: center;
            font-size: 56px;
            color: #ffffff;
          }
          .btn {
            background: #ffffff;
            // box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
            // border-radius: 40px;
            font-size: 32px;
            font-family: $t-f;
            font-weight: 600;
            color: #000000;
            line-height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 280px;
height: 80px;
          }
      }
      .title{
        font-size: 36px;
font-family: $t-f;
font-weight: 600;
color: #000000;
line-height: 50px;
text-align: left;
      }
       .lines {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex: 1;
        width: 92%;
        text-align: center;
        margin: 37px auto;
        .item {
          text-align: center;
          flex: 1;
          
          .txt1 {
            height: 54px;
            font-size: 28px;
            font-family: $t-f;
            font-weight: bold;
            line-height: 54px;
            text-align: center;
            color: #000000;
            white-space: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
          }
          .txt2 {
            height: 40px;
            font-size: 28px;
            font-family: $t-mf;
            font-weight: 600;
            color: #888888;
            line-height: 40px;
            text-align: center;
            margin-top: 18px;
          }
        }
      }
    }
       
    }
   .mlist{
    margin-top: 80px;
    .stitle{
        font-size: 32px;
        color: #ffffff;
        text-align: center;
    }
    .list{
        height: 50vh;
    }
   }

    

  .popup {
    width: 672px;
    background: #ffffff;
    // border-radius: 30px;
        padding: 30px 30px 40px;
        box-sizing: border-box;
    ::v-deep.van-list{
      height: 50vh;
    overflow: auto;
    }
    ::v-deep .van-stepper__input{
          font-size: 42px;
        }
        .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;
            .close{
                width:28px;
            }
             .title{
            width: 100%;
            font-size:32px;
            font-weight: bold;
            color: #000000;
            text-align: left;
           
        }
        }
        .sqtxts{
      font-size: 24px;
      font-family:$t-f;
      font-weight: 600;
      color: #848484;
      line-height: 44px;
      margin: 10px 0 45px;
      padding: 0 22px;
      box-sizing: border-box;
      text-align: left;
    }
    .sqtxt{
      font-size: 32px;
      font-family:$t-f;
      font-weight: 600;
      color: #848484;
      line-height: 44px;
      margin: 10px 0 45px;
      padding: 0 22px;
      box-sizing: border-box;
    }
       .rbox{
            display: flex;
            align-items: center;
            justify-content: center;
           
            .num{
          //  margin: 22px auto;
        font-size: 42px;
        font-family: $t-f;
        font-weight: bold;
          background: linear-gradient(234deg, #106156, #cc1d1d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        }
            .txt{
              color: #2A2A2A;
                font-size: 24px;
                font-weight: bold;
                margin:  0 10px;
            }
            .ico{
              width: 40px;
              height: 40px;
            }
          }
          .flsz{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn {
     width: calc(100% - 200px);
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: linear-gradient(90deg, #F83AFD 0%, #C333FF 31%, #A84BFF 49%, #724EFF 79%, #495AFA 100%);
box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
    }
}

  </style>
